<template>
  <div>
    <div class="profile-title">
      <span
        >Members
        <hr />
        {{ members.length | convertToThousand }}</span
      >
    </div>
    <form class="search">
      <div class="input-wrapper">
        <svg viewBox="0 0 50 50">
          <path
            d="M21 3C11.601563 3 4 10.601563 4 20C4 29.398438 11.601563 37 21 37C24.355469 37 27.460938 36.015625 30.09375 34.34375L42.375 46.625L46.625 42.375L34.5 30.28125C36.679688 27.421875 38 23.878906 38 20C38 10.601563 30.398438 3 21 3 Z M 21 7C28.199219 7 34 12.800781 34 20C34 27.199219 28.199219 33 21 33C13.800781 33 8 27.199219 8 20C8 12.800781 13.800781 7 21 7Z"
          />
        </svg>
        <input type="search" name="" id="" placeholder="Search for members" />
      </div>
      <button type="submit" class="btn">Search</button>
    </form>

    <div class="members-grid">
      <template v-if="members.length > 0">
        <AdminMember
          v-for="member in members"
          :key="member.id"
          :user="member"
        />
      </template>
      <p v-else>No members</p>
    </div>
  </div>
</template>

<script>
import { AdminMember } from "@/components";
import { mapGetters } from "vuex";

export default {
  name: "ViewMembers",
  computed: {
    ...mapGetters(["members"]),
  },
  components: {
    AdminMember,
  },
  async beforeMount() {
    await this.$store.dispatch("getMembers");
  },
};
</script>

<style lang="scss" scoped>
.profile-title {
  margin-top: 0;

  span {
    display: grid;
    align-items: center;
    gap: 10px;
    grid-template-columns: repeat(3, auto);

    hr {
      width: 20px;
    }
  }
}

.search {
  padding: 10px 20px;
  background: $input-color;
  border-radius: 10px;
  display: flex;
  align-items: center;
  /* flex-flow: wrap; */
  justify-content: space-between;

  .input-wrapper {
    display: flex;
    align-items: center;
    flex-basis: 100%;

    svg {
      height: 20px;
      fill: $dark-grey;
    }

    input {
      color: $dark-grey;
      margin-left: 15px;
      margin-right: 15px;
      width: 100%;
    }
  }

  .btn {
    padding: 10px 20px;
    background: whitesmoke;
    border-radius: 100px;
    font-weight: 600;
    transition: 0.2s background ease;

    &:hover {
      background: $dark-grey;
    }
  }
}

.members-grid {
  margin: 30px 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 15px;
}
</style>
